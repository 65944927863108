<app-stretch-view stretchColumnAlign="center stretch">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header-container">
    <div *ngIf="!(layoutService._isSideMenuOpened  | async)">
      <button mat-icon-button (click)="openMenu()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <div *ngIf="isSupervisionCrra(); else elseLogo" class="supervision-logo">
      Supervision CRRA
    </div>
    <ng-template #elseLogo>
      <img class="logo" src="assets/TUCS-LOGO.svg">
    </ng-template>


    <app-menu [displayLogout]="true"></app-menu>
  </div>
</app-stretch-view>