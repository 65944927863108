// Libs
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

// Components
import { SubscribableComponent } from 'src/app/shared/utils/subscribable-component';

// Services
import { ConfigService } from 'src/app/shared/services/core/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends SubscribableComponent {
  isAuthenticated: boolean;

  /**
   * gets redirectionUrl
   * @returns redirectionUrl
   */
  public get redirectionUrl(): string {
    const clientId = 'si-urgence_client';
    const responseType = 'code';
    const scope = 'openid email offline_access';

    // TODO: put nonce ? working in this state
    return `${this.configService.config.keycloak.url}/realms/si-urgence/protocol/openid-connect/auth?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${location.origin}`;
  }

  constructor(
    private configService: ConfigService,
    private oidcSecurityService: OidcSecurityService,
  ) {
    super();
   }

  /**
   * Deconnects a user and revokes his access token and refresh token
   */
  logoffAndRevokeTokens(): void {
    this.subs.push(
      this.oidcSecurityService
        .logoffAndRevokeTokens()
        .subscribe(),
    );
  }
}
