<div *ngIf="isLoading" class="no-freeze-spinner">

  <div id="no-freeze-spinner">
    <div>
      <i class="material-icons">
        local_hospital
      </i>
      <i class="material-icons">
        healing
      </i>
      <i class="material-icons">
        emergency
      </i>
      <div>
      </div>
    </div>
  </div>
</div>

