// Libs
import { Component, OnInit } from '@angular/core';

// Services
import { AuthenticationService } from 'src/app/shared/services/auth/authentication.service';

// Components
import { SubscribableComponent } from 'src/app/shared/utils/subscribable-component';
import { AccountService } from 'src/app/shared/services/auth/account.service';

// Interfaces
import { Utilisateur } from 'src/app/shared/interfaces/utilisateur';
import { LayoutService } from '../../services/business/layout.service';
import { Router } from '@angular/router';

import { StorageService } from 'src/app/shared/services/business/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends SubscribableComponent implements OnInit {
  user: Utilisateur;

  constructor(
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    public layoutService: LayoutService,
    private router: Router,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = this.accountService.user;
    
  }

  openMenu() {
    this.layoutService.setIsSideMenuOpened(true);
  }

  /**
   * Deconnects the user
   */
   logout(): void {
    this.authenticationService
      .logoffAndRevokeTokens();
  }

  isSupervisionCrra(){
    return this.storageService.getNameModule().includes("supervisionCRRA")
  }
}
