// Libs
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';

// Services

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public _isMobile: BehaviorSubject<boolean>;
  public _isSideMenuOpened: BehaviorSubject<boolean>;
  public _isMobileOnly: BehaviorSubject<boolean>;
  public _isHeaderDisplayed: BehaviorSubject<boolean>;

  private isMobile: boolean;

  constructor() {
    this._isMobile = new BehaviorSubject<boolean>(window.innerWidth < 999);
    this.isMobile = window.innerWidth < 999;
    this._isSideMenuOpened = new BehaviorSubject<boolean>(true);
    this._isHeaderDisplayed = new BehaviorSubject<boolean>(false);
    this._isMobileOnly = new BehaviorSubject<boolean>(false);

    combineLatest([this._isMobileOnly, this._isMobile]).subscribe(([isMobileOnly, isMobile]) => {
      this.setIsHeaderDisplayed(isMobile || isMobileOnly);
    });
  }

  updateIsMobile(isMobile: boolean): void {
    if (!isMobile) {
      this.setIsSideMenuOpened(true);
    }
    this._isMobile.next(isMobile);
    this.isMobile = isMobile;
  }

  setIsSideMenuOpened(isOpened: boolean): void {
    if (isOpened || this.isMobile) {
      this._isSideMenuOpened.next(isOpened);
    }
  }

  setIsMobileOnly(isMobileOnly: boolean): void {
    this._isMobileOnly.next(isMobileOnly);
  }

  private setIsHeaderDisplayed(isHeaderDisplayed: boolean): void {
    this._isHeaderDisplayed.next(isHeaderDisplayed);
  }
}
