// Lib imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';

// Modules
import { MaterialModule } from 'src/app/shared/modules/material.module';

// Shared components
import { SubscribableComponent } from 'src/app/shared/utils/subscribable-component';
import { StretchViewComponent } from 'src/app/shared/components/stretch-view/stretch-view.component';
import { SidenavComponent } from '../components/sidenav/sidenav.component';
import { PatientListContainerComponent } from '../components/patient-list-container/patient-list-container.component';
import { MenuComponent } from '../components/menu/menu.component';
import { SidenavLayoutComponent } from 'src/app/shared/components/sidenav-layout/sidenav-layout.component';
import {ProgressSpinnerComponent } from 'src/app/shared/components/progress-spinner/progress-spinner.component'

const components = [
  SubscribableComponent,
  StretchViewComponent,
  SidenavComponent,
  PatientListContainerComponent,
  MenuComponent,
  SidenavLayoutComponent,
  ProgressSpinnerComponent,
];

const modules = [
  CommonModule,
  RouterModule,
  MaterialModule,
  AbilityModule
];

const providers = [
  { provide: Ability, useValue: new Ability() },
  { provide: PureAbility, useExisting: Ability }
]

@NgModule({
  declarations: [
    components
  ],
  imports: [
    modules
  ],
  exports: [
    modules,
    components
  ],
  providers //: []
})
export class SharedModule { }
