import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoleService } from '../shared/services/http/role.service';

@Component({
  selector: 'tucs-redirect',
  template: '',
})
export class RedirectComponent implements OnInit {

  constructor(
    private roleService: RoleService,
    private router: Router) {

  }

  ngOnInit(): void {

    this.roleService.fetchRelatedModules().subscribe(modules => {

      modules = modules.filter(module => module.nom !== 'Admin');
      if (modules.length != 0) {
        this.router.navigate([(modules[0].nom).toLowerCase()]);
      }
      else {
        this.router.navigate([`/utilisateur/me`]);
      }

    });

  }

}
