// Libs
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ConfigService } from 'src/app/shared/services/core/config.service';
import { Module } from '../../interfaces/module';

// Interfaces


@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) { }

  public fetchModulesNavigation(): Observable<Module[]> {
    return this.http.get<Module[]>(`${this.configService.config.admin.url}/module/navigation`);
  }
}
