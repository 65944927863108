<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <mat-icon class="menu-icon">apps</mat-icon>
</button>
<mat-menu #menu="matMenu" class="menu-grid">
    <button *ngFor="let module of modules | async" mat-menu-item (click)="navigateToModule(module)">
      <span>{{ module.nom }}</span>
    </button>
      <div class="separator"></div>
      <div mat-menu-item disabled>{{user.prenom + " " + user.nom}}</div>
      <button mat-menu-item (click)="account()">Modifier mes informations</button>
      <button mat-menu-item (click)="logout()">Déconnexion</button>
</mat-menu>