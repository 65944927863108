// LIbs
import { Component } from '@angular/core';

// Interfaces
import { Utilisateur } from '../../interfaces/utilisateur';
import { AccountService } from '../../services/auth/account.service';
import { LayoutService } from '../../services/business/layout.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  user: Utilisateur;

  constructor(
    private accountService: AccountService,
    public layoutService: LayoutService,
  ) {
    this.user = this.accountService.user;
  }
}
