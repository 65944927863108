import {Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Structure} from '../../interfaces/structure';
import {StructureService} from '../../services/http/structure.service';
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import { filterStructures } from 'src/app/shared/utils/autocomplete';
import { StorageService } from '../../services/business/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'tucs-patient-list-container',
  templateUrl: './patient-list-container.component.html',
  styleUrls: ['./patient-list-container.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  ]
})

export class PatientListContainerComponent implements OnInit {

  @Input() action: string;
  @ViewChild('inputTrigger') inputTrigger: ElementRef;

  _structures: Observable<Structure[]>
  structures: Structure[];

  filteredStructures: Structure[];

  groupedStructures: Structure[];
  filteredGroupedStructures: Structure[];

  ensembleStructures: Structure[];

  canSelectStructure: Observable<boolean>;

  date: Date;
  maxDate: Date;
  minDate: Date;
  defaultDate: string;
  selectedWorkspace: Structure;
  defaultValue : string = '';

  dateOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  constructor(
    private structureService: StructureService,
    private _adapter: DateAdapter<any>,
    public storageService: StorageService,
    private snackbar: MatSnackBar,

  ) {
    this._adapter.setLocale('fr');
    this.date = this.storageService.getDateFilter();
    this.maxDate = new Date();
    this.minDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.defaultDate = this.date ? 
      this.date.toLocaleString('fr-FR', this.dateOption) : 
      this.maxDate.toLocaleString('fr-FR', this.dateOption);
    this.filterChange(this.storageService.getDateFilter());
  }

  ngOnInit() {
    this._structures = this.structureService.fetchAll(this.action).pipe(
      tap(structures => {
        this.ensembleStructures = structures;
        this.groupedStructures = [...structures];
        this.filteredGroupedStructures = [...structures];
        this.filteredStructures = structures.flatMap(g => g.enfants);
        this.filteredStructures.push(...this.filteredGroupedStructures)
        this.structures = this.filteredStructures;
        if (!this.selectedWorkspace) {
          if (this.storageService.getSelectedWorkspace()) {
            this.selectedWorkspace = this.storageService.getSelectedWorkspace();
          } else {
            this.selectedWorkspace = this.filteredGroupedStructures[0]
            this.structureService.toggleStructures(this.filteredGroupedStructures[0]?.enfants);
          }
        }
      })
    );
    this.canSelectStructure = this._structures.pipe(
      map(structures => structures.length > 1)
    );
  }

  filterOptions(value: string) {
    this.filteredStructures = filterStructures(value, this.structures);
    this.filteredGroupedStructures = JSON.parse(JSON.stringify(this.groupedStructures));

    this.filteredGroupedStructures = this.filteredGroupedStructures.map(group => {
      group.enfants = [...group.enfants.filter(e => !!this.filteredStructures.find(s => s.id === e.id))];
      return group;
    })
    this.filteredGroupedStructures = this.filteredGroupedStructures.filter(g => g.enfants.length !== 0 || g.nom.toLowerCase().includes(value.toLowerCase()));
  }

  filterChange(value) {
    this.storageService.setDateFilter(value);
  }

  isDisabled(groupId: number): boolean {
    return this.groupedStructures.find(g => g.id === groupId).disabled;
  }

  displayFn(structure: Structure): string {
    return structure && structure.nom ? structure.nom : '';
  }

  selectServiceChange(servicesId: number[]) {
    const services = servicesId.map(id => this.selectedWorkspace.enfants.find(service => service.id === id));
    this.structureService.toggleStructures(services);
  }

  get selectedServices() {
    return this.storageService.selectedServices.pipe(map(services => services.map(service => service.id)));
  }

  toggleGroup(workspace: Structure) {    
    this.storageService.setSelectedWorkspace(workspace)
    const enfants = this.ensembleStructures.find(st => st.id === workspace.id)?.enfants;
    if (enfants){
      this.structureService.toggleStructures(enfants);
      workspace.enfants = enfants;
    }
    this.selectedWorkspace = workspace;
    this.inputTrigger.nativeElement.blur();
  }

  refreshData()
  {
    this.snackbar.open('Données à jour', 'Fermer', {
      duration: 3000,
      panelClass: ['snack-bar-success']
    })
  }
}
