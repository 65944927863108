<div class="filters" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start space-around" fxFlex fxLayout.xs="column">
    <!-- BTN REFRESH  mobile-->
    <div fxLayout="column" fxLayoutAlign="strech" fxShow.xs="true" fxShow.sm="false" fxShow.md="false"
      fxShow.lg="false" fxShow.xl="false">
      <button mat-raised-button color="primary" (click)="refreshData()">
        Rafraîchir <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <mat-form-field *ngIf="canSelectStructure | async; else oneStructure" class="select-instance" appearance="legacy">
      <mat-label>Etablissement</mat-label>
      <div fxLayout="row">
        <input type="text" #inputTrigger placeholder="Etablissement" matInput [defaultValue]="selectedWorkspace?.nom"
          [matAutocomplete]="autoComplete" (keyup)="filterOptions($event.target.value)" tabindex="-1">
        <div class="mat-select-arrow-wrapper">
          <div class="mat-select-arrow"></div>
        </div>
      </div>
      <mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="displayFn" [panelWidth]="auto">
        <mat-option *ngFor="let option of filteredGroupedStructures" [label]="option.nom" [value]="option"
          (click)="toggleGroup(option)">
          {{option.nom}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <ng-template #oneStructure>
      <div *ngIf="selectedWorkspace" class="select-instance" style="padding: 8px;"> {{ selectedWorkspace.nom }}</div>
    </ng-template>
    <mat-form-field class="date-field" appearance="legacy">
      <mat-label>Choisir une date</mat-label>
      <input matInput tabindex="-1" [matDatepicker]="picker" [min]="minDate" [max]="maxDate"
        [defaultValue]="defaultDate" (dateChange)="filterChange($event.target.value)" disabled>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [disabled]="false" [startAt]="date"></mat-datepicker>
    </mat-form-field>
    <!-- BTN REFRESH  laptop-->
    <div fxLayout="column" fxLayoutAlign="center" class="btnRefresh" fxShow.xs="false">
      <button id="button" mat-mini-fab color="primary" (click)="refreshData()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="selectedWorkspace && selectedWorkspace.enfants.length > 1" appearance="legacy" fxFlex>
    <div fxLayout="row" fxLayoutAlign="start space-around" fxFlex>
      <mat-form-field class="select-instance" appearance="legacy">
        <mat-label>Services</mat-label>
        <mat-select [value]="selectedServices | async" multiple (selectionChange)="selectServiceChange($event.value)">
          <mat-option *ngFor="let structure of selectedWorkspace.enfants" [value]="structure.id">
            {{ structure.nom }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="no-structure" fxFlex fxLayoutAlign="center center" *ngIf="!(_structures| async)?.length">
  <h1>
    Pas de service accessible
  </h1>
</div>
<ng-content></ng-content>