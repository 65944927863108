// Libs
import { NgModule } from '@angular/core';
import { AuthModule, StsConfigLoader } from 'angular-auth-oidc-client';

// Services
import { initializeFactory } from 'src/app/shared/services/core/initialize.factory';
import { ConfigService } from 'src/app/shared/services/core/config.service';

@NgModule({
  imports: [AuthModule.forRoot({
    loader: {
      provide: StsConfigLoader,
      useFactory: initializeFactory,
      deps: [ConfigService],
    },
  })],
  providers: [
  ],
  exports: [AuthModule],
})
export class OidcConfigModule { }
