<app-stretch-view>
  <div fxLayout="column" [ngClass]="{'sidenav-container': !(layoutService._isMobile | async)}" fxFlex fxLayoutGap="25px">
    <div class="sidenav-inner-container" fxLayout="column" fxFlex fxLayoutAlign="start stretch">
      <div *ngIf="!(layoutService._isMobile | async)" fxLayout="row"  fxLayoutAlign="space-between center" class="sidenav-header">        
        <div class="sidenav-title" >
          <div *ngIf="isSupervisionCrra(); else elseLogo" class="supervision-size">
            Supervision<br>CRRA
          </div>
          <ng-template #elseLogo>
            <img class="logo" src="assets/TUCS-LOGO.svg">
          </ng-template>          
        </div>
        <div *ngIf="user" class="user-label">{{user.prenom + " " + user.nom}}</div>
      </div>
      <ng-content></ng-content>
  </div>
  </div>
</app-stretch-view>
