import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IDossierListFilters } from "src/app/supervision-crra/interfaces/dossierList";
import { ISamuSupervision } from "../../interfaces/samu";
import { Structure } from "../../interfaces/structure";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  readonly KEY_SELECTED_WORKSPACE = 'SELECTED_WORKSPACE';
  readonly KEY_SELECTED_SERVICES = 'SELECTED_SERVICES';
  readonly KEY_DATE_FILTER = 'DATE_FILTER';
  readonly KEY_SELECTED_SAMU = 'SELECTED_SAMU';
  readonly KEY_SUPERVISION_FILTERS = 'SUPERVISION_FILTERS';
  readonly KEY_NAME_MODULE= 'NAME_MODULE';

  selectedWorkspace: BehaviorSubject<Structure>;
  selectedServices: BehaviorSubject<Structure[]>;
  dateFilter: BehaviorSubject<Date>;

  selectedSamu: BehaviorSubject<ISamuSupervision>;
  supervisionFilters: BehaviorSubject<Partial<IDossierListFilters>>;

  titleModule:BehaviorSubject<string>;

  constructor() {
    this.selectedWorkspace = new BehaviorSubject(this.getSelectedWorkspaceFromStorage());
    this.selectedServices = new BehaviorSubject(this.getSelectedServicesFromStorage());
    this.dateFilter = new BehaviorSubject(this.getDateFilterFromStorage());

    this.selectedSamu = new BehaviorSubject(this.getSelectedSamuFromStorage());
    this.supervisionFilters = new BehaviorSubject(this.getSupervisionFiltersFromStorage());
    this.titleModule = new BehaviorSubject(this.getNameModuleFromStorage())
  }
  getNameModule(): string {
   return this.titleModule.value; 
  }

  getSelectedWorkspace(): Structure {
    return this.selectedWorkspace.value;
  }

  getSelectedServices(): Structure[] {
    return this.selectedServices.value;
  }

  getDateFilter(): Date {
    return this.dateFilter.value;
  }

  getSelectedSamu(): ISamuSupervision {
    return this.selectedSamu.value;
  }

  getSupervisionFilters(): Partial<IDossierListFilters> {
    return this.supervisionFilters.value;
  }

  getNameModuleFromStorage():string{
    return sessionStorage[this.KEY_NAME_MODULE] ? sessionStorage[this.KEY_NAME_MODULE] : '';
  }

  getSelectedWorkspaceFromStorage(): Structure {
    return sessionStorage[this.KEY_SELECTED_WORKSPACE] ? JSON.parse(sessionStorage[this.KEY_SELECTED_WORKSPACE]) as Structure : null;
  }

  getSelectedServicesFromStorage(): Structure[] {
    return sessionStorage[this.KEY_SELECTED_SERVICES] ? JSON.parse(sessionStorage[this.KEY_SELECTED_SERVICES]) as Structure[] : [];
  }

  getDateFilterFromStorage(): Date {
    return sessionStorage[this.KEY_DATE_FILTER] ? new Date(JSON.parse(sessionStorage[this.KEY_DATE_FILTER])) : new Date();
  }

  getSelectedSamuFromStorage(): ISamuSupervision {
    return sessionStorage[this.KEY_SELECTED_SAMU] ? JSON.parse(sessionStorage[this.KEY_SELECTED_SAMU]) as ISamuSupervision : null;
  }

  getSupervisionFiltersFromStorage(): Partial<IDossierListFilters> {
    return sessionStorage[this.KEY_SUPERVISION_FILTERS] ? JSON.parse(sessionStorage[this.KEY_SUPERVISION_FILTERS]) as Partial<IDossierListFilters> : {};
  }

  setNameModule(nameModule:string){
    this.titleModule.next(nameModule);
    sessionStorage[this.KEY_NAME_MODULE] = nameModule;
  }

  setSelectedWorkspace(workspace: Structure): void {
    this.selectedWorkspace.next(workspace);
    sessionStorage[this.KEY_SELECTED_WORKSPACE] = JSON.stringify(workspace);
  }

  setSelectedServices(services: Structure[]): void {
    this.selectedServices.next(services);
    sessionStorage[this.KEY_SELECTED_SERVICES] = JSON.stringify(services);
  }

  setDateFilter(date: Date): void {
    this.dateFilter.next(date);
    sessionStorage[this.KEY_DATE_FILTER] = JSON.stringify(date);
  }

  setSelectedSamu(samu: ISamuSupervision): void {
    this.selectedSamu.next(samu);
    sessionStorage[this.KEY_SELECTED_SAMU] = JSON.stringify(samu);
  }

  setSupervisionFilters(supervisionFilters: Partial<IDossierListFilters>): void {
    this.supervisionFilters.next(supervisionFilters);
    sessionStorage[this.KEY_SUPERVISION_FILTERS] = JSON.stringify(supervisionFilters);
  }

  clearSupervisionFilters(){
    sessionStorage[this.KEY_SUPERVISION_FILTERS] = "";
  }

  clearService() {
    sessionStorage[this.KEY_SELECTED_SERVICES] = ""
  }

  clearWorkSpace() {
    sessionStorage[this.KEY_SELECTED_WORKSPACE] = ""
  }


  clearAll() {
    this.clearService();
    this.clearWorkSpace();
  }

}
