// Libs
import { Component, HostListener, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

// Services
import { AccountService } from 'src/app/shared/services/auth/account.service';
import { LayoutService } from 'src/app/shared/services/business/layout.service'

// Components
import { SubscribableComponent } from 'src/app/shared/utils/subscribable-component';
import { RoleService } from './shared/services/http/role.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends SubscribableComponent implements OnInit {
  newAuthInit: boolean;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private accountService: AccountService,
    private layoutService: LayoutService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.accountService.isLogged.subscribe(async isLogged => {
        if (isLogged) {
          await this.accountService.fetchMe().toPromise();
        }
        await this.accountService.updateAbility();
        this.newAuthInit = isLogged && !!this.accountService.user;
      })
    )
    this.newAuthInit = !!this.accountService.user
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.layoutService.updateIsMobile(window.innerWidth < 999);
  }
}
