import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Structure, StructureType } from "../../interfaces/structure";
import { ConfigService } from "../core/config.service";
import { StorageService } from "../business/storage.service";

@Injectable({
  providedIn: 'root'
})
export class StructureService {
  private path = 'structure';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private storageService: StorageService,
  ) {
  }

  public fetchAll(action: string): Observable<Structure[]> {
    let params = new HttpParams();
    params = params.set('module', this.configService.module);
    return this.http.get<Structure[]>(`${this.configService.config.admin.url}/${this.path}/module`, { params }).pipe(
      tap(structures => {
        // select the first structure by default
        if (structures[0]) {
          if (structures[0].enfants[0]) {
            if (!this.storageService.getSelectedServicesFromStorage()) {
              this.storageService.setSelectedServices([...structures[0].enfants]);
            }
          }
        }
      }));
  }

  isStructureSelected(structure: Structure): number {
    return this.storageService.getSelectedServices().findIndex(s => s.id === structure.id);
  }

  toggleStructures(structures: Structure[]): void {
    this.storageService.setSelectedServices(structures);
  }

  /**
   * Fetch All Structures
   */
  public fetchStructureForSelect(type?: StructureType): Observable<Structure[]> {
    let params = new HttpParams();
    if (type) params = params.set('type', type);
    return this.http.get<Structure[]>(`${this.configService.config.admin.url}/structure/list`, {params});
  }
}
