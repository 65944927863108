// Libs
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// Interfaces
import { NavElement } from 'src/app/shared/interfaces/sidenav';
import { AccountService } from 'src/app/shared/services/auth/account.service';
import { Utilisateur } from '../../interfaces/utilisateur';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { LayoutService } from '../../services/business/layout.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Input() sideNavStyle: string;
  navElements: NavElement[];
  user: Utilisateur;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    public layoutService: LayoutService,
  ) {
    this.accountService.currentUser.subscribe(user => {
      this.user = user
    });
  }

  /**
   * Deconnects the user
   */
  logout(): void {
    this.authenticationService.logoffAndRevokeTokens();
  }

  isSupervisionCrra(){
    return this.router.url.includes('supervision-crra');
  }

  /**
   * Access to one's account
   */
  account(): void {
    this.router.navigate([`/utilisateur/me`]);
  }
  
}
