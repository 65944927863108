// Libs
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// Modules
import {OidcConfigModule} from 'src/app/shared/modules/oidc-config.module';
import {AppRoutingModule} from 'src/app/app-routing.module';
import {SharedModule} from 'src/app/shared/modules/shared.module';

// Services
import {InterceptorService} from 'src/app/shared/services/core/interceptor.service';

// Components
import {AppComponent} from 'src/app/app.component';
import {MainComponent} from './shared/components/main/main.component';
import {HeaderComponent} from './shared/components/header/header.component';
import { RedirectComponent } from './redirect/redirect.component';

// Layout




@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    RedirectComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OidcConfigModule,
    SharedModule
  ],
  providers: [
    {
      // Add the http interceptor
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
