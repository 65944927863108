import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({ template: '' })
export class SubscribableComponent implements OnDestroy {
  protected subs: Subscription[] = [];

  /**
   * Unsuscribes from listened observables (avoid memeroy leaks)
   */
  ngOnDestroy(): void {
    this.subs.filter(s => !s.closed).forEach(s => s.unsubscribe());
    this.subs = [];
  }
}
