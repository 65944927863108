// Libs
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ConfigService } from 'src/app/shared/services/core/config.service';
import { Role, RoleFilters } from '../../interfaces/role';
import { Module } from '../../interfaces/module';
import { EntityService } from './entity.service';

// Interfaces


@Injectable({
  providedIn: 'root'
})
export class RoleService extends EntityService<Role> {

  constructor(
    configService: ConfigService,
    http: HttpClient,
  ) {
    super(
      configService,
      http,
      'role',
    );
  }

  /**
   * Fetch relatedRoles
   */
  public fetchRelatedRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.configService.config.admin.url}/role/related`);
  }

  /**
 * Fetch a related role of a user in a structure
 * @param structureId
 */
  public fetchRelatedRoleByStructureId(structureId: number): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.configService.config.admin.url}/role/structure/${structureId}`);
  }

  /**
   * Export Roles
   */
  public exportRoles(filters: RoleFilters = null, sortBy: string = null, orderBy: string = null): Observable<string> {
    let params = new HttpParams();
    if (filters) {
      Object.entries(filters).map(([key, value]) => {
        params = params.set(key, (value as string));
      });
    }
    if (sortBy) params = params.set('sortBy', sortBy);
    if (orderBy) params = params.set('orderBy', orderBy);
    return this.http.get(`${this.configService.config.admin.url}/role/export`, { params, responseType: 'text' });
  }

  /**
   * Import Roles
   */
  public importRoles(roleFile: File): Observable<Role[]> {
    const formData: FormData =  new FormData();
    formData.append('file', roleFile);
    return this.http.post<Role[]>(`${this.configService.config.admin.url}/role/import`, formData);
  }

  /**
   * Fetch Related Modules
   */
   public fetchRelatedModules(): Observable<Module[]> {
    return this.http.get<Module[]>(`${this.configService.config.admin.url}/module/navigation`);
  }

}
