// Libs
import { Component } from '@angular/core';
import { LayoutService } from '../../services/business/layout.service';

// Interfaces

@Component({
  selector: 'app-sidenav-layout',
  templateUrl: './sidenav-layout.component.html',
  styleUrls: ['./sidenav-layout.component.scss'],
})
export class SidenavLayoutComponent {
  date: Date;
  constructor(public layoutService: LayoutService) {}
}
