<mat-sidenav-container fxFlex>
  <!-- Sidenav -->
  <mat-sidenav 
    fxFlex 
    [mode]="(layoutService._isMobile | async) ? 'over' : 'side'"
    [opened]="layoutService._isSideMenuOpened | async"
    [ngClass]="{'sidenav-mobile': (layoutService._isMobile | async)}"
    class="tucs-hide-on-print"
    [disableClose]="true"
  >
    <ng-content select="[sidenav]"></ng-content>
  </mat-sidenav>
  <!-- Content -->
  <mat-sidenav-content 
    fxFlex
    fxLayoutAlign="center center"
    class="sidenav-content-container"
    [ngClass]="{'sidenav-content-container-mobile': (layoutService._isMobile | async)}"
  >
    <ng-content select="[main]"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
