// LIbs
import { Component, Input } from '@angular/core';

// Interfaces
import { Utilisateur } from '../../interfaces/utilisateur';
import { AccountService } from '../../services/auth/account.service';
import { LayoutService } from '../../services/business/layout.service';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent {
  @Input() isLoading: boolean;
}